@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Whitney";
    src: url("/public/fonts/WhitneySSm-Book-Adv_Web.woff") format("woff");
    font-weight: 300;
  }

  @font-face {
    font-family: "Whitney";
    src: url("/public/fonts/WhitneySSm-Book-Adv_Web.woff") format("woff");
    font-weight: 400;
  }

  @font-face {
    font-family: "Whitney";
    src: url("/public/fonts/WhitneySSm-Medium-Adv_Web.woff") format("woff");
    font-weight: 500;
  }

  @font-face {
    font-family: "Whitney";
    src: url("/public/fonts/WhitneySSm-Semibold-Adv_Web.woff") format("woff");
    font-weight: 600;
  }

  @font-face {
    font-family: "Whitney";
    src: url("/public/fonts/WhitneySSm-Bold-Adv_Web.woff") format("woff");
    font-weight: 700;
  }

  #root {
    @apply w-full;
    --toastify-color-error: #feebe5;
    --toastify-text-color-error: #000;
    --toastify-color-success: #e9fbe7;
    --toastify-text-color-success: #000;
    --toastify-toast-width: 420px;
  }

  .toast-error {
    border: 2px solid #a1002f;
  }

  .toast-success {
    border: 2px solid #0c5c34;
  }

  .toast-error,
  .toast-success {
    border-radius: 8px !important;
    box-shadow: 0px 9px 12px rgba(15, 60, 151, 0.07),
      0px 3px 16px rgba(15, 60, 151, 0.06), 0px 5px 6px rgba(15, 60, 151, 0.1) !important;
    bottom: 16px !important;
    width: calc(100% - 32px) !important;
    left: 16px;
  }

  .toast-error .Toastify__toast-body,
  .toast-success .Toastify__toast-body {
    align-items: start;
    padding-right: 15px;
  }

  .toast-error .Toastify__toast-icon {
    color: #a1002f;
    margin-right: 16px;
    padding-top: 5px;
  }

  .toast-success .Toastify__toast-icon {
    color: #0c5c34;
    margin-right: 16px;
    padding-top: 5px;
  }

  .toast-error .Toastify__close-button,
  .toast-success .Toastify__close-button {
    color: #424242;
    padding: 5px;
    padding-top: 10px;
  }

  .toast-error .Toastify__close-button svg,
  .toast-success .Toastify__close-button svg {
    height: 22px;
    width: 22px;
  }

  .toast-error .Toastify__toast-body h5,
  .toast-success .Toastify__toast-body h5 {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
  }

  .toast-error .Toastify__toast-body ul {
    padding-left: 30px;
  }

  .toast-error .Toastify__toast-body ul li {
    list-style: disc;
  }

  * {
    @apply box-border;
  }

  body {
    @apply m-0 flex min-h-screen flex-col items-center p-0 transition duration-200 ease-in-out;
    font-family: -apple-system, BlinkMacSystemFont, "Adelle Sans", "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif !important;
  }

  h1 {
    @apply text-center font-primary text-4xl text-black md:text-[44px] md:leading-[64px] xl:text-5xl;
  }

  h2 {
    @apply text-center font-primary text-3xl text-black md:text-[38px] md:leading-[42px] xl:text-[40px] xl:leading-[48px];
  }

  h3 {
    @apply text-center font-primary text-2xl text-black md:text-[34px] xl:text-[36px];
  }

  h4 {
    @apply text-center font-primary text-xl text-black md:text-[26px] xl:text-[28px];
  }

  h5 {
    @apply text-center font-primary text-lg text-black md:text-[22px] md:leading-[32px] xl:text-[24px];
  }

  select {
    background-position: 95% 50%;
  }

  .verifyIdCard {
    box-shadow: 0px 9px 12px rgba(15, 60, 151, 0.07),
      0px 3px 16px rgba(15, 60, 151, 0.06), 0px 5px 6px rgba(15, 60, 151, 0.1);
  }

  .idDisplayed {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  }
}
